import React from 'react'
import { ThemeProvider } from 'theme-ui'

import { OppositeWallTheme } from '../../styles/theme'
import { Header } from './Header'
import { CartProvider } from '../../contexts/CartContext'

export const PageLayout = ({ children, ...props }) => {
  return (
    <React.Fragment>
      <ThemeProvider theme={OppositeWallTheme} {...props}>
        <CartProvider {...props}>
          <Header />
          {children}
        </CartProvider>
      </ThemeProvider>
    </React.Fragment>
  )
}
