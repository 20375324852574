import { BrowserRouter as Router } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'

import { ShopifyProvider } from './contexts/ShopifyContext'
import Routes from './routes'

import './styles/reset.css'
import './styles/font.css'
import './styles/base.css'

const queryClient = new QueryClient()

function App() {
  return (
    <ShopifyProvider>
      <QueryClientProvider client={queryClient}>
        <Router>
          <Routes />
        </Router>
      </QueryClientProvider>
    </ShopifyProvider>
  )
}

export default App
