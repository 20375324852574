import React, { useReducer, createContext } from 'react'

export const initialState = {
  isOpen: false,
}

export const CartContext = createContext(initialState)

export const actions = {
  TOGGLE_CART_OPEN: 'cart/toggle_cart_open',
}

const reducer = (state, action) => {
  switch (action.type) {
    case actions.TOGGLE_CART_OPEN:
      return {
        ...state,
        isOpen: action.payload,
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

export const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const store = React.useMemo(() => ({ state, dispatch }), [state])

  return <CartContext.Provider value={store}>{children}</CartContext.Provider>
}
