import axios from 'axios'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
})

const checkAuth = params => (localStorage.getItem('auth') ? Promise.resolve() : Promise.reject())

const checkError = error => {
  const status = error.status
  if ([401, 403].includes(status)) {
    localStorage.removeItem('auth')
    return Promise.reject()
  }

  // other error code (404, 500, etc): no need to log out
  return Promise.resolve()
}

const getIdentity = () => {
  const { user } = JSON.parse(localStorage.getItem('auth'))
  user ? Promise.resolve(user) : Promise.reject()
}

const getLayouts = () => {
  return api.get('/layouts')
}

const getLayout = id => {
  return api.get(`/layouts/${id}`)
}

const login = async ({ username, password }) => {
  try {
    const response = await api.post('/auth/login', { email: username, password })
    localStorage.setItem('auth', JSON.stringify(response.data))

    return Promise.resolve(response)
  } catch (err) {
    return Promise.reject(err)
  }
}

const logout = async () => {
  try {
    await api.post('/auth/logout')
    localStorage.removeItem('auth')

    return Promise.resolve()
  } catch (err) {
    return Promise.reject(err)
  }
}

export { api, checkAuth, checkError, getIdentity, getLayouts, getLayout, login, logout }
