/** @jsxImportSource theme-ui */

const CartQtyFlipperAdd = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="10"
    aria-hidden="true"
    focusable="false"
    role="presentation"
    viewBox="0 0 20 20"
    {...props}
  >
    <path d="M17.409 8.929h-6.695V2.258c0-.566-.506-1.029-1.071-1.029s-1.071.463-1.071 1.029v6.671H1.967C1.401 8.929.938 9.435.938 10s.463 1.071 1.029 1.071h6.605V17.7c0 .566.506 1.029 1.071 1.029s1.071-.463 1.071-1.029v-6.629h6.695c.566 0 1.029-.506 1.029-1.071s-.463-1.071-1.029-1.071z"></path>
  </svg>
)

export default CartQtyFlipperAdd
