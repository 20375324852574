/** @jsxImportSource theme-ui */

const Cross = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5392 13.6401L7.95898 8.05787L8.66622 7.35089L14.2463 12.933L19.824 7.3552L20.5312 8.06231L14.9532 13.6402L20.5312 19.2203L19.824 19.9272L14.2461 14.3473L8.66616 19.9273L7.95905 19.2202L13.5392 13.6401Z"
    />
  </svg>
)

export default Cross
