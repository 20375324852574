import styles from './NotFound.module.css'

function NotFound() {
  return (
    <div className={styles.container}>
      <h1>Not Found</h1>
    </div>
  )
}

export default NotFound
