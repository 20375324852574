import { Create, PasswordInput, required, SimpleForm, TextInput } from 'react-admin'
import React from 'react'

export const UserCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <TextInput source="email" validate={[required()]} />
      <PasswordInput source="password" validate={[required()]} />
      <PasswordInput source="password2" label="Repeat Password" validate={[required()]} />
      <TextInput source="firstName" />
      <TextInput source="lastName" />
    </SimpleForm>
  </Create>
)
