import { Route, Switch } from 'react-router-dom'

import { LayoutDetail } from './pages/LayoutDetail'
import { LayoutsList } from './pages/LayoutsList'

import AdminDashboard from './pages/Admin'
import NotFound from './pages/NotFound'
import { PageLayout } from './components/PageLayout/PageLayout'

function RouteWithLayout({ children, ...props }) {
  return (
    <Route {...props}>
      <PageLayout>{children}</PageLayout>
    </Route>
  )
}

function Routes() {
  return (
    <Switch>
      <RouteWithLayout path="/layout/:id">
        <LayoutDetail />
      </RouteWithLayout>
      <RouteWithLayout exact path="/">
        <LayoutsList />
      </RouteWithLayout>
      <Route path="/admin">
        <AdminDashboard />
      </Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  )
}

export default Routes
