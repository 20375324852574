import { useContext } from 'react'

import { actions, ShopifyContext } from '../contexts/ShopifyContext'
import { api } from '../utils/api'

async function createCheckout(dispatch) {
  const response = await api.post('/checkout')
  dispatch({
    type: actions.CREATE_CHECKOUT,
    payload: response.data,
  })
}

async function createCheckoutItems(dispatch, ids) {
  const response = await api.post('/checkout/add', {
    ids: Array.isArray(ids) ? ids : [ids],
  })
  dispatch({
    type: actions.CREATE_CHECKOUT_ITEM,
    payload: response.data,
  })
}

async function removeCheckoutItem(dispatch, id) {
  const response = await api.post('/checkout/delete', { id })
  dispatch({
    type: actions.DELETE_CHECKOUT_ITEM,
    payload: response.data,
  })
}

async function updateCheckoutItem(dispatch, id, data) {
  const response = await api.post('/checkout/update', { data, id })
  dispatch({
    type: actions.UPDATE_CHECKOUT_ITEM,
    payload: response.data,
  })
}

export default function useShopify() {
  const context = useContext(ShopifyContext)
  if (context === undefined) {
    throw new Error('useShopify must be used within a ShopifyProvider')
  }

  return {
    ...context,
    createCheckout,
    createCheckoutItems,
    removeCheckoutItem,
    updateCheckoutItem,
  }
}
