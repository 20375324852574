import Chip from '@material-ui/core/Chip'
import get from 'lodash/get'
import {
  Datagrid,
  DateField,
  DeleteButton,
  EditButton,
  ImageField,
  List,
  TextField,
} from 'react-admin'

const ProductNamesField = ({ record, source }) => {
  const value = get(record, source)
  const products = get(record, '_products')

  const getTitle = productIds => {
    const product = productIds?.product ? get(products, productIds.product) : {}
    const variant =
      product?.variants && productIds?.variant ? get(product.variants, productIds.variant) : {}

    return product?.title && variant?.title
      ? `${product.title} - ${variant.title}`
      : product?.title && !variant?.title
      ? product.title
      : null
  }

  return value && products ? (
    <div style={{ display: 'flex', gap: '4px', flexWrap: 'wrap' }}>
      {value.map((product, i) => {
        const frame = getTitle(product.frame)
        const poster = getTitle(product.poster)
        return (
          <>
            {poster && <Chip label={poster} key={i} />}
            {frame && <Chip label={frame} key={i} />}
          </>
        )
      })}
    </div>
  ) : null
}

export const LayoutList = props => (
  <List {...props}>
    <Datagrid>
      <TextField source="title" />
      <ImageField source="image" />
      <ProductNamesField source="products" />
      <DateField source="updatedAt" />
      <EditButton />
      <DeleteButton undoable={true} />
    </Datagrid>
  </List>
)
