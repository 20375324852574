/** @jsxImportSource theme-ui */
import React from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from 'react-query'

import { getLayouts } from '../../utils/api'
import { Container } from '../../components/PageLayout/Container'
import { LayoutCard } from '../../components/LayoutCard'

export function LayoutsList() {
  const { isLoading, isError, data, error } = useQuery('layouts', getLayouts)

  return (
    <Container>
      <ul sx={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }} data-cy="layout-list">
        {isLoading && <h1>Loading...</h1>}
        {isError && <h1>{error.message}</h1>}
        {data?.data &&
          data.data.map((layout, i) => (
            <li sx={{ display: 'block', width: 'calc(50% - 10px)' }} key={i}>
              <Link to={`layout/${layout.id}`}>
                <LayoutCard layout={layout} />
              </Link>
            </li>
          ))}
      </ul>
    </Container>
  )
}
