/** @jsxImportSource theme-ui */

export const Container = props => (
  <div
    {...props}
    sx={{
      margin: '0 auto',
      maxWidth: '1280px',
    }}
  />
)
