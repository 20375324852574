import {
  ArrayInput,
  AutocompleteInput,
  FormDataConsumer,
  ReferenceInput,
  SelectInput,
  SimpleFormIterator,
} from 'react-admin'

export const Products = () => (
  <ArrayInput source="products">
    <SimpleFormIterator>
      <FormDataConsumer>
        {({ getSource, formData, scopedFormData }) => (
          <div
            style={{
              'align-items': 'stretch',
              display: 'flex',
              'flex-direction': 'row',
              gap: '8px',
              marginLeft: '8px',
            }}
          >
            <div>
              <span>Poster</span>
              <ReferenceInput
                label="Search..."
                source={getSource('poster.product')}
                reference="products"
              >
                <AutocompleteInput optionText="title" />
              </ReferenceInput>
              {scopedFormData?.poster?.product ? (
                <ReferenceInput
                  filter={{ product: scopedFormData.poster.product }}
                  label="Select one"
                  reference="variants"
                  source={getSource('poster.variant')}
                >
                  <SelectInput emptyText="Select one" optionText="title" />
                </ReferenceInput>
              ) : null}
            </div>
            <div style={{ flex: 1 }}>
              <span>Frame</span>
              <ReferenceInput
                label="Search..."
                source={getSource('frame.product')}
                reference="products"
              >
                <AutocompleteInput optionText="title" />
              </ReferenceInput>
              {scopedFormData?.frame?.product ? (
                <ReferenceInput
                  filter={{ product: scopedFormData.frame.product }}
                  label="Select one"
                  reference="variants"
                  source={getSource('frame.variant')}
                >
                  <SelectInput emptyText="Select one" optionText="title" />
                </ReferenceInput>
              ) : null}
            </div>
          </div>
        )}
      </FormDataConsumer>
    </SimpleFormIterator>
  </ArrayInput>
)
