/** @jsxImportSource theme-ui */

const CartQtyFlipperSubtract = props => (
  <svg
    width="10"
    height="10"
    aria-hidden="true"
    focusable="false"
    role="presentation"
    viewBox="0 0 20 20"
    {...props}
  >
    <path d="M17.543 11.029H2.1A1.032 1.032 0 0 1 1.071 10c0-.566.463-1.029 1.029-1.029h15.443c.566 0 1.029.463 1.029 1.029 0 .566-.463 1.029-1.029 1.029z"></path>
  </svg>
)

export default CartQtyFlipperSubtract
