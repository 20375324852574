import { Datagrid, DateField, DeleteButton, EditButton, List, TextField } from 'react-admin'

export const UserList = props => (
  <List {...props}>
    <Datagrid>
      <TextField source="email" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <DateField source="updatedAt" />
      <EditButton />
      <DeleteButton undoable={true} />
    </Datagrid>
  </List>
)
