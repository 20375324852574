/** @jsxImportSource theme-ui */
import React from 'react'
import useCart from '../../hooks/useCart'

import useShopify from '../../hooks/useShopify'
import { Cart } from '../Cart/Cart'
import ShoppingBag from '../Icons/ShoppingBag'

export const Header = props => {
  const {
    dispatch: cartDispatch,
    state: { isOpen: isCartOpen },
    toggleCartOpen,
  } = useCart()
  const { dispatch, state, updateCheckoutItem } = useShopify()

  const handleUpdate = (id, quantity) => {
    updateCheckoutItem(dispatch, id, { quantity })
  }

  const handleCartClose = () => toggleCartOpen(cartDispatch, false)

  return (
    <React.Fragment>
      {isCartOpen && state?.checkout ? (
        <div sx={{ position: 'relative', zIndex: '3' }}>
          <Cart
            checkout={state.checkout}
            isCartOpen={isCartOpen}
            handleCartClose={handleCartClose}
            update={handleUpdate}
          />
        </div>
      ) : null}
      <header
        sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          zIndex: '2',
          height: '110px',
        }}
        {...props}
      >
        <div
          sx={{
            width: '100%',
            maxWidth: '1372px',
            margin: 'auto',
            padding: '0 40px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <p>menu</p>
          <button
            sx={{
              variant: 'buttons.primary',
              '::after': state?.checkout?.lineItems?.length > 0 && {
                content: '" "',
                position: 'relative',
                top: '-15px',
                right: '-50%',
                display: 'block',
                width: '15px',
                height: '15px',
                backgroundColor: 'cartRed',
                border: 'solid white 1px',
                borderRadius: '50%',
                boxSizing: 'border-box',
              },
            }}
            onClick={() => toggleCartOpen(cartDispatch, true)}
          >
            <ShoppingBag sx={{ fill: 'text' }} />
          </button>
        </div>
        <div
          aria-live="polite"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            py: '10px',
            fontFamily: 'body',
            fontSize: '0.844rem',
            backgroundColor: 'announcementBar',
            color: 'white',
          }}
        >
          Get 25% off all posters over CA$75*
        </div>
      </header>
    </React.Fragment>
  )
}
