/** @jsxImportSource theme-ui */

const ShoppingBag = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2339 6.875C10.4225 5.6846 10.6992 4.7301 11.0585 3.97826C11.4324 3.19591 11.9145 2.59309 12.5165 2.18987C13.1249 1.78239 13.7869 1.625 14.4375 1.625C15.0881 1.625 15.7501 1.78239 16.3585 2.18987C16.9605 2.59309 17.4427 3.19591 17.8166 3.97826C18.1759 4.7301 18.4526 5.6846 18.6412 6.875H23.3289L25.095 24.9444H3.77094L5.54196 6.875H10.2339ZM12.2621 6.875C12.4207 5.99532 12.6281 5.33213 12.863 4.84064C13.1218 4.29901 13.3936 4.00961 13.6295 3.85159C13.859 3.69784 14.1191 3.625 14.4375 3.625C14.756 3.625 15.016 3.69784 15.2456 3.85159C15.4815 4.00961 15.7532 4.29901 16.0121 4.84064C16.2469 5.33213 16.4543 5.99532 16.613 6.875H12.2621ZM10.0155 8.875C9.96377 9.63596 9.93752 10.4677 9.93752 11.375H11.9375C11.9375 10.4393 11.9669 9.60953 12.0206 8.875H16.8544C16.9081 9.60953 16.9375 10.4393 16.9375 11.375H18.9375C18.9375 10.4677 18.9113 9.63596 18.8596 8.875H21.5148L22.89 22.9444H5.97654L7.35552 8.875H10.0155Z"
    />
  </svg>
)
export default ShoppingBag
