import { useContext } from 'react'

import { actions, CartContext } from '../contexts/CartContext'

function toggleCartOpen(dispatch, open) {
  dispatch({
    type: actions.TOGGLE_CART_OPEN,
    payload: open,
  })
}

export default function useCart() {
  const context = useContext(CartContext)
  if (context === undefined) {
    throw new Error('useCart must be used within a CartProvider')
  }

  return {
    ...context,
    toggleCartOpen,
  }
}
