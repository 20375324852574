export const OppositeWallTheme = {
  fonts: {
    body: 'Lato, system-ui, sans-serif',
    heading: 'Unna, serif',
  },
  colors: {
    text: '#333e48',
    faded: '#989fac',
    cartRed: '#eb0029',
    announcementBar: '#c69d75',
    background: '#fff',
  },
  transitions: {
    fast: '0.125s',
    medium: '0.225s',
    slow: '0.300s',
    xslow: '0.450s',
  },
  links: {
    breadcrumbs: {
      color: 'text',
      fontSize: '0.938rem',
      textDecoration: 'none',
      ':hover': {
        textDecoration: 'underline',
      },
    },
  },
  selects: {
    default: {
      p: '10px',
      borderColor: 'faded',
      border: 'solid 1px',
      color: 'text',
      fontSize: '1.125rem',
      fontFamily: 'body',
      appearance: 'none',
      textIndent: '0.01px',
      textOverflow: '',
    },
  },
  buttons: {
    primary: {
      padding: '7.5px 10px',
    },
    checkout: {
      transitionDuration: theme => theme.transitions.fast,
      padding: '16px 0',
      justifyContent: 'center',
      background: theme => theme.colors.text,
      color: '#fff',
      fontFamily: theme => theme.fonts.body,
      fontSize: '0.938rem',
      textTransform: 'uppercase',
      letterSpacing: '0.25rem',
      ':hover': {
        transform: 'scale(1.01)',
      },
      ':active': { transform: 'scale(0.98)' },
      ':focus': {
        outline: 'none',
        boxShadow: theme => `0 0 0 2px ${theme.colors.background}, 0 0 0 3px ${theme.colors.text}`,
      },
    },
    flipper: {
      height: '30px',
      padding: '0 5px',
      fill: '#444',
      borderColor: theme => theme.colors.faded,
      ':hover': {
        backgroundColor: 'black',
        fill: 'white',
      },
    },
  },
}
