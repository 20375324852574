/** @jsxImportSource theme-ui */
import PropTypes from 'prop-types'
import React, { useState, useMemo, useEffect } from 'react'
import { keyframes } from '@emotion/react'

import Cross from '../Icons/Cross'
import { LineItem } from './LineItem'

const fadeIn = keyframes`
from {
  opacity: 0;
}
to {
  opacity: 1.0;
}
`

const fadeOut = keyframes`
from {
  opacity: 1.0;
}
to {
  opacity: 0;
}
`

const slideIn = keyframes`
from {
  transform: translateX(100%);
}
to {
  transform: translateX(0);
}
`

const slideOut = keyframes`
from {
  transform: translateX(0);
}
to {
  transform: translateX(100%);
}
`

const slideUp = keyframes`
from {
  transform: translateY(60px);
  opacity:0;
}
to {
  transform: translateY(0px);
  opacity:1;
}`

export function Cart({ checkout, update, handleCartClose }) {
  const [shouldCartClose, setShouldCartClose] = useState(false)

  useEffect(() => {
    window.addEventListener('keydown', handleKeypress)
    return () => {
      window.removeEventListener('keydown', handleKeypress)
    }
  })

  const openCheckout = () => {
    window.open(checkout.webUrl)
  }

  const handleCartAnimateAndClose = () => {
    setShouldCartClose(true)
    setTimeout(() => handleCartClose(), 450)
  }

  const handleKeypress = ({ key }, callback) => {
    key === 'Escape' && handleCartAnimateAndClose()
  }

  const lineItems = useMemo(
    () =>
      checkout.lineItems?.length
        ? checkout.lineItems.map((lineItem, i) => (
            <LineItem key={lineItem.id.toString()} lineItem={lineItem} update={update} index={i} />
          ))
        : null,
    [checkout, update]
  )

  return (
    <React.Fragment>
      <div
        sx={{
          position: 'fixed',
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(236, 236, 236, 0.6)',
          animation: theme =>
            `${shouldCartClose ? fadeOut : fadeIn} cubic-bezier(0.165, 0.84, 0.44, 1) ${
              theme.transitions.medium
            } forwards`,
        }}
        onClick={handleCartAnimateAndClose}
      />
      <div
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: 'min(100%, 400px)',
          height: '100%',
          position: 'fixed',
          right: 0,
          backgroundColor: 'white',
          animation: theme =>
            `${shouldCartClose ? slideOut : slideIn} cubic-bezier(0.165, 0.84, 0.44, 1) ${
              theme.transitions.xslow
            } forwards`,
        }}
      >
        <header
          sx={{
            display: 'flex',
            flexShrink: '0',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            height: '120px',
            padding: '0 30px',
            borderBottom: 'solid 1px #989FAC',
          }}
        >
          <h2 sx={{ fontFamily: 'heading', fontSize: '1.875rem' }}>Cart</h2>
          <button
            onClick={handleCartAnimateAndClose}
            sx={{ display: 'flex', variant: 'buttons.primary' }}
          >
            <Cross sx={{ stroke: 'text' }} />
          </button>
        </header>
        {lineItems ? (
          <React.Fragment>
            <ul
              sx={{
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
                overflowY: 'auto',
                height: '100%',
                alignItems: 'center',
                gap: '22px',
                padding: '22px 0',
              }}
            >
              {lineItems}
            </ul>
            <footer
              sx={{
                display: 'flex',
                flexShrink: '0',
                boxSizing: 'border-box',
                flexDirection: 'column',
                padding: '22px 30px 30px 30px',
                borderColor: '#989FAC',
                borderTop: 'solid 1px',
                opacity: 0,
                animation: `${slideUp} cubic-bezier(0.165, 0.84, 0.44, 1) 850ms ${
                  checkout.lineItems.length <= 4 ? checkout.lineItems.length * 125 + 125 : 600
                }ms forwards`,
              }}
            >
              <div
                sx={{ display: 'flex', justifyContent: 'space-between', color: 'text', mb: '10px' }}
              >
                <span
                  sx={{ textTransform: 'uppercase', fontSize: '0.844rem', letterSpacing: '4px' }}
                >
                  Subtotal
                </span>
                <span sx={{ fontSize: '0.938rem' }}>${checkout.subtotalPrice}</span>
              </div>
              <p
                sx={{
                  fontSize: '0.938rem',
                  color: 'text',
                  opacity: '0.8',
                  mb: '10px',
                  lineHeight: 1.5,
                }}
              >
                Shipping, taxes, and discounts codes calculated at checkout.
              </p>
              <button
                sx={{
                  variant: 'buttons.checkout',
                  width: '100%',
                }}
                onClick={openCheckout}
              >
                Check out
              </button>
            </footer>
          </React.Fragment>
        ) : (
          <div
            sx={{
              display: 'flex',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            Your cart is empty.
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

Cart.propTypes = {
  checkout: PropTypes.shape({
    lineItems: PropTypes.arrayOf(
      PropTypes.shape({
        quantity: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        variant: PropTypes.shape({
          image: PropTypes.shape({
            src: PropTypes.string.isRequired,
          }),
          price: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
        }).isRequired,
      })
    ),
    subtotalPrice: PropTypes.string.isRequired,
    totalPrice: PropTypes.string.isRequired,
    totalTax: PropTypes.string.isRequired,
    webUrl: PropTypes.string.isRequired,
  }).isRequired,
  update: PropTypes.func.isRequired,
}
