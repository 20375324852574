import React from 'react'
import { Edit, SimpleForm, TextInput, Toolbar, required } from 'react-admin'

import { Products } from './components/Products'
import { StyledImageUploadInput } from './components/StyledImageUploadInput'
import { transform } from './utils'

const formatLayoutImage = value => {
  if (!value || typeof value === 'string') {
    // Value is null or the url string from the backend, wrap it in an object so the form input can handle it
    return { url: value }
  } else {
    // Else a new image is selected which results in a value object already having a preview link under the url key
    return value
  }
}

export const LayoutEdit = props => (
  <Edit {...props} transform={transform}>
    <SimpleForm toolbar={<Toolbar alwaysEnableSaveButton />}>
      <TextInput source="title" />
      <TextInput source="author" />
      <StyledImageUploadInput format={formatLayoutImage} validate={[required()]} />
      <Products />
      <TextInput multiline source="description" />
    </SimpleForm>
  </Edit>
)
