import React, { useEffect, useReducer, createContext } from 'react'

import useShopify from '../hooks/useShopify'

export const initialState = {
  checkout: null,
  products: [],
}

export const ShopifyContext = createContext(initialState)

export const actions = {
  CREATE_CHECKOUT: 'shopify/create_checkout',
  CREATE_CHECKOUT_ITEM: 'shopify/create_checkout_item',
  DELETE_CHECKOUT_ITEM: 'shopify/delete_checkout_item',
  UPDATE_CHECKOUT_ITEM: 'shopify/update_checkout_item',
  GET_PRODUCTS: 'shopify/get_products',
}

const reducer = (state, action) => {
  switch (action.type) {
    case actions.CREATE_CHECKOUT:
    case actions.CREATE_CHECKOUT_ITEM:
    case actions.DELETE_CHECKOUT_ITEM:
    case actions.UPDATE_CHECKOUT_ITEM:
      return {
        ...state,
        checkout: action.payload.checkout,
      }
    case actions.GET_PRODUCTS:
      return {
        ...state,
        products: action.payload.products,
      }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

const InternalShopifyProvider = ({ children }) => {
  const { createCheckout, dispatch, state } = useShopify()

  useEffect(() => {
    if (!state.checkout) {
      createCheckout(dispatch)
    }
  }, [createCheckout, dispatch, state.checkout])

  return children
}

export const ShopifyProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <ShopifyContext.Provider value={{ state, dispatch }}>
      <InternalShopifyProvider dispatch={dispatch}>{children}</InternalShopifyProvider>
    </ShopifyContext.Provider>
  )
}
