import { makeStyles } from '@material-ui/core/styles'
import { ImageField, ImageInput } from 'react-admin'

const useStyles = makeStyles({
  imginput: {
    maxWidth: '256px',
  },
})

export const StyledImageUploadInput = props => {
  const classes = useStyles()
  return (
    <ImageInput
      {...props}
      accept="image/*"
      className={classes.imginput}
      maxSize={12000000}
      source="image"
    >
      <ImageField source="url" title="title" />
    </ImageInput>
  )
}
