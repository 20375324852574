import { Create, SimpleForm, TextInput } from 'react-admin'
import React from 'react'

import { Products } from './components/Products'
import { StyledImageUploadInput } from './components/StyledImageUploadInput'
import { transform } from './utils'

export const LayoutCreate = props => (
  <Create {...props} transform={transform}>
    <SimpleForm redirect="list">
      <TextInput source="title" />
      <TextInput source="author" />
      <StyledImageUploadInput />
      <Products />
      <TextInput multiline source="description" />
    </SimpleForm>
  </Create>
)
