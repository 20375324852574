import React from 'react'
import { Edit, SimpleForm, TextInput, Toolbar, required, PasswordInput } from 'react-admin'

export const transform = data => {
  const { id, createdAt, updatedAt, ...body } = data
  return body
}

export const UserEdit = props => (
  <Edit {...props} transform={transform}>
    <SimpleForm toolbar={<Toolbar alwaysEnableSaveButton />}>
      <TextInput source="email" validate={[required()]} />
      <PasswordInput source="password" />
      <PasswordInput source="password2" label="Repeat Password" />
      <TextInput source="firstName" />
      <TextInput source="lastName" />
    </SimpleForm>
  </Edit>
)
