/** @jsxImportSource theme-ui */
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import { Container } from '../../components/PageLayout/Container'
import { LayoutCard } from '../../components/LayoutCard'
import { LayoutProducts } from './LayoutProducts'
import { getLayout } from '../../utils/api'

export function LayoutDetail() {
  const { id } = useParams()
  const { isIdle, isLoading, isError, data, error } = useQuery(
    ['layouts', id],
    () => getLayout(id),
    {
      enabled: !!id,
      staleTime: 1000 * 60 * 5,
    }
  )

  return (
    <Container>
      {(isIdle || isLoading) && <h1>Loading...</h1>}
      {isError && <h1>{error.message}</h1>}
      {data?.data && (
        <React.Fragment>
          <div
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              my: 48,
              alignItems: 'center',
            }}
          >
            <div
              sx={{
                width: '100%',
                mb: 20,
                textAlign: 'center',
                variant: 'links.breadcrumbs',
                ':hover': { textDecoration: 'none' },
              }}
            >
              <Link to="/" sx={{ variant: 'links.breadcrumbs' }}>
                Gallery Walls
              </Link>
              &nbsp;/&nbsp;
              <Link to={`/layout/${data.data.id}`} sx={{ variant: 'links.breadcrumbs' }}>
                {data.data.title}
              </Link>
            </div>
            <h2 sx={{ fontFamily: 'heading', fontSize: '2.188rem' }}>Gallery Walls</h2>
          </div>
          <div sx={{ display: 'flex', flexDirection: ['column', 'column', 'row'], gap: '16px' }}>
            <div sx={{ width: 'min(520px, 100%)', px: [0, '60px'] }}>
              <LayoutCard layout={data.data} />
              <p sx={{ mt: '12px', fontFamily: 'heading', fontSize: '0.938rem' }}>
                {data.data.description}
              </p>
            </div>
            <div sx={{ flex: 2 }}>
              <LayoutProducts collections={data.data.products} products={data.data._products} />
            </div>
          </div>
        </React.Fragment>
      )}
    </Container>
  )
}
