/** @jsxImportSource theme-ui */
import PropTypes from 'prop-types'
import { keyframes } from '@emotion/react'

import { CartQtyFlipper } from './CartQtyFlipper'

const lineItemAnimateIn = keyframes`
  from {
    transform: translateY(60px);
    opacity:0;
  }
  to {
    transform: translateY(0px);
    opacity:1;
  }`

export function LineItem({ lineItem, update, index }) {
  const {
    title,
    variant: { image, price, title: variantTitle },
  } = lineItem

  return (
    <li
      sx={{
        width: 'min(100%, 325px)',
        display: 'flex',
        gap: '20px',
        paddingBottom: '22px',
        borderColor: '#989FAC0',
        ':not(:last-child)': { borderBottom: 'solid 1px' },
        opacity: '0',
        '--animation-order': index,
        animation: `${lineItemAnimateIn} cubic-bezier(0.165, 0.84, 0.44, 1) 850ms calc(var(--animation-order) * 125ms) forwards`,
      }}
    >
      <div sx={{ flex: 1 }}>
        {image ? <img src={image.src} alt={`${title}`} sx={{ width: '100%' }} /> : null}
      </div>
      <div sx={{ flex: 2 }}>
        <div>
          <p sx={{ fontSize: '1.125rem', color: 'text' }}>{title}</p>
          <p sx={{ fontSize: '0.938rem', color: 'text', mt: '8px' }}>{variantTitle}</p>
        </div>

        <div
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: '11px',
          }}
        >
          <div sx={{ width: '50%' }}>
            <CartQtyFlipper lineItem={lineItem} update={update} />
          </div>
          <span>${price}</span>
        </div>
      </div>
    </li>
  )
}

LineItem.propTypes = {
  lineItem: PropTypes.shape({
    quantity: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    variant: PropTypes.shape({
      image: PropTypes.shape({
        src: PropTypes.string.isRequired,
      }),
      price: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  update: PropTypes.func.isRequired,
}
