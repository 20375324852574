/** @jsxImportSource theme-ui */

import { useState } from 'react'

import CartQtyFlipperAdd from '../Icons/CartQtyFlipperAdd'
import CartQtyFlipperSubtract from '../Icons/CartQtyFlipperSubtract'

export const CartQtyFlipper = ({ lineItem, update }) => {
  const [qty, setQty] = useState(lineItem.quantity)

  const decrement = props => {
    setQty(qty - 1)
    update(lineItem.id, qty - 1)
  }

  const increment = () => {
    setQty(qty + 1)
    update(lineItem.id, qty + 1)
  }

  const handleQtyInputChange = ({ target: { value } }) => {
    setQty(value)
  }

  const handleBlur = ({ target: { value } }) => {
    update(lineItem.id, parseInt(value))
  }

  return (
    <div
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        border: 'solid 1px',
        borderColor: 'faded',
        ':focus-within': {
          outline: theme => `${theme.colors.text} solid`,
        },
      }}
    >
      <button onClick={decrement} sx={{ borderRight: 'solid 1px', variant: 'buttons.flipper' }}>
        <CartQtyFlipperSubtract />
      </button>
      <input
        size="4"
        sx={{
          flexGrow: '1',
          fontSize: '1rem',
          fontFamily: 'body',
          textAlign: 'center',
          border: 'none',
          outline: 'none',
        }}
        value={qty}
        onChange={handleQtyInputChange}
        onBlur={handleBlur}
      />
      <button onClick={increment} sx={{ borderLeft: 'solid 1px', variant: 'buttons.flipper' }}>
        <CartQtyFlipperAdd />
      </button>
    </div>
  )
}
