/** @jsxImportSource theme-ui */
import useCart from '../../hooks/useCart'

import useShopify from '../../hooks/useShopify'

const CollectionPair = ({ collection, products }) => {
  const poster = {
    product: collection?.poster?.product ? products[collection?.poster?.product] : null,
    variant: collection?.poster?.variant
      ? products[collection?.poster?.product].variants[collection?.poster?.variant]
      : null,
  }
  const frame = {
    product: collection?.frame?.product ? products[collection?.frame?.product] : null,
    variant: collection?.frame?.variant
      ? products[collection?.frame?.product].variants[collection?.frame?.variant]
      : null,
  }

  return (
    (frame && poster && (
      <article
        sx={{ display: 'flex', py: 1, gap: '30px', borderColor: 'text', borderBottom: 'solid 1px' }}
      >
        <img
          src={poster.product.images[0].src}
          alt={poster.product.images[0].altText}
          title={poster.product.images[0].altText}
          sx={{ width: '105px', flexShrink: 0 }}
        />
        <div sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <h3 sx={{ fontFamily: 'heading', fontSize: '2.188rem', mb: 2 }}>
            {poster.product.title}
          </h3>
          <div
            sx={{
              display: 'flex',
              flexDirection: ['column', 'column', 'row'],
              width: '100%',
              gap: 3,
            }}
          >
            <div sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <span
                sx={{
                  fontFamily: 'body',
                  fontSize: '0.875rem',
                  letterSpacing: '0.219rem',
                  textTransform: 'uppercase',
                }}
              >
                Size
              </span>
              <span sx={{ p: '10px 0', fontSize: '1.125rem', fontFamily: 'body' }}>
                {poster.variant.title}
              </span>
            </div>
            <div sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <span
                sx={{
                  fontFamily: 'body',
                  fontSize: '0.875rem',
                  letterSpacing: '0.219rem',
                  textTransform: 'uppercase',
                }}
              >
                Frame
              </span>
              <span sx={{ p: '10px 0', fontSize: '1.125rem', fontFamily: 'body' }}>
                {frame.product.title}
              </span>
            </div>
          </div>
        </div>
      </article>
    )) || <p>Collection doesn't contain matching poster and frame.</p>
  )
}

export function LayoutProducts({ collections, products }) {
  const { dispatch: cartDispatch, toggleCartOpen } = useCart()
  const { createCheckoutItems, dispatch } = useShopify()

  const handleBuy = async variantIds => {
    await createCheckoutItems(dispatch, variantIds)
    toggleCartOpen(cartDispatch, true)
  }

  const getAllVariants = () => {
    return collections.reduce((previous, current) => {
      previous.push(current.poster.variant, current.frame.variant)
      return previous
    }, [])
  }
  return (
    (collections && products && (
      <ul sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '16px' }}>
        {collections.map((collection, i) => {
          return (
            <li key={i}>
              <CollectionPair collection={collection} products={products} />
            </li>
          )
        })}
        <li sx={{ display: 'flex' }}>
          <button
            sx={{ width: '100%', variant: 'buttons.checkout' }}
            onClick={() => handleBuy(getAllVariants())}
          >
            Add set to Shopping Bag
          </button>
        </li>
      </ul>
    )) ||
    'Products not found.'
  )
}
