/** @jsxImportSource theme-ui */

export const LayoutCard = ({ layout }) => {
  return (
    <article sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      <img
        alt={layout.description ?? layout.title}
        title={layout.description ?? layout.title}
        src={layout.image}
        sx={{ width: 'min(100%, 400px)', margin: '0 auto' }}
      />
      <section sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <h3 sx={{ display: 'inline', mt: '20px', fontSize: '2.188rem', color: 'text' }}>
          {layout.title}
        </h3>
      </section>
    </article>
  )
}
