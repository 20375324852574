import { createBrowserHistory as createHistory } from 'history'
import { fetchUtils, Admin, Resource } from 'react-admin'
import simpleRestProvider from 'ra-data-simple-rest'

import { checkAuth, checkError, getIdentity, login, logout } from '../../utils/api'
import { LayoutCreate, LayoutEdit, LayoutList } from './layouts'
import { UserCreate, UserEdit, UserList } from './users'

const history = createHistory({ basename: '/admin/' })

const fetchJson = (url, options = {}) => {
  return fetchUtils.fetchJson(url.replace('/api/', '/api/admin/'), {
    ...options,
    credentials: 'include',
  })
}

const dataProvider = simpleRestProvider(process.env.REACT_APP_API_BASE_URL, fetchJson)
const imageUploadURL = `${process.env.REACT_APP_API_BASE_URL}/upload`

const uploadImage = async ({ data }) => {
  const formData = new FormData()
  formData.append('image', data.image.rawFile)
  const imgUploadOptions = {
    method: 'POST',
    body: formData,
  }

  const {
    json: { location },
  } = await fetchJson(imageUploadURL, imgUploadOptions)

  return location
}

const customDataProvider = {
  ...dataProvider,
  create: async (resource, params) => {
    let data = params.data
    if (data && data.image !== params.previousData?.image) {
      data.image = data.image ? await uploadImage(params) : ''
    }
    return dataProvider.create(resource, { data })
  },
  update: async (resource, params) => {
    let data = params.data
    if (data && data.image !== params.previousData?.image) {
      data.image = data.image ? await uploadImage(params) : ''
    }

    return dataProvider.update(resource, {
      id: params.id,
      data,
    })
  },
}

const authProvider = {
  checkAuth,
  checkError,
  getIdentity,
  getPermissions: params => Promise.resolve(),
  login,
  logout,
}

function AdminDasboard() {
  return (
    <Admin authProvider={authProvider} dataProvider={customDataProvider} history={history}>
      <Resource create={LayoutCreate} edit={LayoutEdit} list={LayoutList} name="layouts" />
      <Resource create={UserCreate} edit={UserEdit} list={UserList} name="users" />
      <Resource name="products" />
      <Resource name="variants" />
    </Admin>
  )
}

export default AdminDasboard
